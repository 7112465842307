import React from "react"
import Row from "../layout/row"
import styled from "@emotion/styled"
import useContacts from "../../hooks/use-contacts"

const FooterWrapper = styled.div`
  min-height: 120px;
  z-index: 0;
  background-color: ${props => props.theme.colors.footer};
  color: ${props => props.theme.colors.footerFont};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  ${props => props.theme.mobile} {
    background-color: transparent;
  }
`

const Footer = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  ${props => props.theme.mobile} {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: ${props => props.theme.colors.footer};
    height: 100%;
    padding: 20px 13px;
    margin-bottom: 13px;
    border-radius: 5px;
    font-size: 1rem;
  }
`

const ContactsWrapper = styled.div`
  text-align: right;
  ${props => props.theme.mobile} {
    text-align: left;
  }
`

const FooterLogo = styled.div`
  text-align: center;
  font-size: 1rem;
  ${props => props.theme.mobile} {
    display: none;
  }
`

const ContactsInfo = styled.span`
  text-transform: lowercase;
`

const Header = styled.span`
  display: none;
  font-size: 1.3rem;
  margin-bottom: 10px;
  ${props => props.theme.mobile} {
    display: block;
  }
`

export default function FooterComponent() {
  const contactsData = useContacts()
  return (
    <FooterWrapper>
      <Row>
        <Footer>
          <FooterLogo>
            ИНСТИТУТ ТЕРРИТОРИАЛЬНОГО <br /> ПЛАНИРОВАНИЯ <br />
            г. Пермь
          </FooterLogo>
          <ContactsWrapper>
            <Header>Контакты</Header>
            Электронная почта: <ContactsInfo>{contactsData.eMail}</ContactsInfo>
            <br />
            Телефон: <ContactsInfo>{contactsData.phoneNumber}</ContactsInfo>
            <br />
            График работы:{" "}
            <ContactsInfo
              dangerouslySetInnerHTML={{ __html: contactsData.workingHours }}
            />
            <br />
            Приемные часы:{" "}
            <ContactsInfo>{contactsData.businessHours}</ContactsInfo>
          </ContactsWrapper>
        </Footer>
      </Row>
    </FooterWrapper>
  )
}
