import React, { useState, useEffect } from "react"
import NavLink from "./navlink"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import CloseIcon from "../../assets/close.svg"

const NavigationWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding: 0 22px;
  background: ${props =>
    props.light ? "none" : props.theme.colors.blockBackground};
  border: 2px solid transparent;
  border-radius: 5px;
  mix-blend-mode: normal;
  z-index: 800;
  ${props => props.theme.laptop} {
    position: relative;
    background: none;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
    display: block;
    display: ${props => (props.mobileOpen ? "none" : "block")};
  }
`
const LanguageLinkMobile = styled(({ mobileOpen, ...props }) => (
  <Link {...props} />
))`
  display: none;
  ${props => props.theme.laptop} {
    display: block;
    font-size: 1.5rem;
    color: white;
    margin-left: 1rem;
    padding: 0;
    line-height: 1;
    background-color: transparent;
    border-style: none;
  }
`

const CloseButton = styled.button`
  display: none;
  ${props => props.theme.laptop} {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0;
    color: ${props => props.theme.colors.footerFont};
    background-color: transparent;
    border-style: none;
  }
`

const Overlay = styled.div`
  ${props => props.theme.laptop} {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: ${props => (props.mobileOpen ? "0" : "100%")};
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    background: rgba(
      21,
      21,
      21,
      0.9
    ); /* Black fallback color */ /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    ${NavLink} {
      white-space: nowrap;
      display: ${props => (props.mobileOpen ? "none" : "block")};
      padding: 1rem;
      font-weight: 500;
      text-decoration: none;
      font-size: 1.2rem;
      color: ${props => props.theme.colors.footerFont};
      transition: opacity 5s ease-in-out;
    }
  }
`

const IconBar = styled.div`
  width: 2.3rem;
  height: 3px;
  background-color: black;
  margin: 0.5rem 0;
  opacity: 0.7;
`
const Icon = styled.div`
  display: inline-block;
  cursor: pointer;
  display: none;
  ${props => props.theme.laptop} {
    display: block;
  }
`
const ButtonsWrapper = styled.div`
  display: ${props => (props.mobileOpen ? "none" : "flex")};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1.5rem;
`

const StyledCloseIcon = styled(CloseIcon)`
  fill: white;
  height: 20px;
  width: 20px;
`

const IndexPageLink = styled(({ ...props }) => <NavLink {...props} />)`
  display: none;
  ${props => props.theme.laptop} {
    display: block;
  }
`

export default function Navigation({ light }) {
  const [isMenuOpen, handleMenu] = useState(true)

  const disableScroll = () => {
    document.body.classList.add("lock-scroll")
  }

  const enableScroll = () => {
    document.body.classList.remove("lock-scroll")
  }

  useEffect(() => {
    if (isMenuOpen) {
      enableScroll()
    } else {
      disableScroll()
    }
  }, [isMenuOpen])

  function handleBarClick() {
    handleMenu(isMenuOpen => !isMenuOpen)
  }

  return (
    <>
      <Overlay mobileOpen={isMenuOpen}>
        <ButtonsWrapper mobileOpen={isMenuOpen}>
          <LanguageLinkMobile to="/contacts-en">EN</LanguageLinkMobile>
          <CloseButton onClick={handleBarClick}>
            <StyledCloseIcon />
          </CloseButton>
        </ButtonsWrapper>

        <NavigationWrapper mobileOpen={isMenuOpen} light={light}>
          <IndexPageLink lightlink={light} to="/">
            Главная
          </IndexPageLink>
          <NavLink partiallyActive={true} lightlink={light} to="/portfolio">
            Портфолио
          </NavLink>
          <NavLink partiallyActive={true} lightlink={light} to="/information">
            Информация
          </NavLink>
          <NavLink partiallyActive={true} lightlink={light} to="/contacts">
            Контакты
          </NavLink>
        </NavigationWrapper>
      </Overlay>
      <Icon onClick={handleBarClick}>
        <IconBar></IconBar>
        <IconBar></IconBar>
        <IconBar></IconBar>
      </Icon>
    </>
  )
}
