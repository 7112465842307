import React from "react"
import Navigation from "./navigation"
import styled from "@emotion/styled"
import Logo from "./logo"
import { Link } from "gatsby"

const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin: auto 0;
  ${props => props.theme.latop} {
    margin: 0 auto;
    max-width: 90vw;
    padding: 0.5rem 0;
    border-bottom: none;
  }
`

const HeaderWrapper = styled.div`
  ${props => props.theme.laptop} {
    width: 100%;
    z-index: 100;
    background-color: rgb(255, 255, 255, 0.9);
    margin-top: 15px;
  }
`

const LanguageButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const LanguageLink = styled(({ light, ...props }) => <Link {...props} />)`
  color: ${props => (props.light ? "black" : "white")};
  font-size: 24px;
  margin: 0.5rem 2.9rem 0.1rem;
  cursor: pointer;
  ${props => props.theme.laptop} {
    margin: 10px 0;
    color: black;
    display: none;
  }
`


const Wrapper = styled.div`
  max-width: 93vw;
  margin: 0 auto;
  width: 1780px;
  z-index: 999;
`



export default function Header({ light }) {
  return (
    <Wrapper>
      <LanguageButtonWrapper>
        <LanguageLink light={light} to="/contacts-en">
          EN
        </LanguageLink>
      </LanguageButtonWrapper>
      <HeaderWrapper>
        <HeaderStyled>
          <Logo light={light} />
          <Navigation light={light} />
        </HeaderStyled>
      </HeaderWrapper>
    </Wrapper>
  )
}
