import React from "react"
import Icon from "../../assets/logo.svg"
import Nyicon from "../../assets//ny-icons/ny_logo2.svg"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const MainTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${props => (props.light ? "black" : "white")};
`
const SecondTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 0em;
  color: #645b5b;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  .cls-4 {
    fill: ${props => (props.light ? "black" : "white")};
  }

  .cls-1 {
    fill: #acc70b;
  }

  .cls-2 {
    fill: #0972eb;
  }

  .cls-3 {
    fill: #f90100;
  }

  .ny-logo {
    width: 12%;
    ${props => props.theme.laptop} {
      display: none;
    }
  }
`

const Logo = styled.div`
  margin-right: 20px;
  width: 57px;
  height: 55px;
`

const TitleWrapper = styled.div`
  ${props => props.theme.laptop} {
    display: none;
  }
  margin-right: 20px;
`
// const NyIconWrapper = styled.div`
//   ${props => props.theme.laptop} {
//     display: none;
//   }
// `

export default function Navigation({ light }) {
  return (
    <Link to="/">
      <LogoWrapper light={light}>
        <Logo>
          <Icon />
        </Logo>
        <TitleWrapper>
          <MainTitle light={light}>
            Институт территориального планирования
          </MainTitle>
          <SecondTitle>
            государственное бюджетное учреждение Пермского края
          </SecondTitle>
        </TitleWrapper>
      </LogoWrapper>
    </Link>
  )
}
