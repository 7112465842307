import React from "react"
import Helmet from "react-helmet"

const Meta = () => {
  return (
    <Helmet>
      <html lang="ru" />
      <title>Институт Территориального планирования</title>
      <meta name="description" content="муниципальное казенное учреждение" />
    </Helmet>
  )
}
export default Meta
