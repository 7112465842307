import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ActiveLink = props => (
  <Link {...props} lightlink="" activeClassName="current-page" />
)

const NavLink = styled(ActiveLink)`
  display: block;
  color: ${props => (props.lightlink ? "black" : "white")};
  font-size: 1.2rem;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  padding: 10px 24px 10px 24px;

  cursor: pointer;

  &.current-page {
    background: #8c8c8c;
    border-radius: 5px;
    color: white;
    &:hover {
      color: ${props => props.theme.colors.hoverLink};
    }
  }

  &:hover {
    color: ${props =>
      props.lightlink
        ? props.theme.colors.lightHoverLink
        : props.theme.colors.hoverLink};
  }

  &:last-of-type {
    margin-right: 0px;
  }
`

export default NavLink
