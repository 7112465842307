import React from "react"
import { css } from "@emotion/react"
import GlobalStyles from "./global"
import Meta from "./meta"
import styled from "@emotion/styled"
import Header from "../header"
import Footer from "../footer"

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  ${props => props.theme.mobile} {
    margin-bottom: 0rem;
  }
  /* ${props => props.theme.tablet} {
    margin-top: 5rem;
  } */
`

const Layout = ({ children, light }) => {
  return (
    <>
      <GlobalStyles />
      <Meta />
      <article
        css={css`
          margin: 0rem auto;
          display: flex;
          flex-direction: column;
          min-height: 100vh;
          align-items: stretch;
          h1 {
            padding-bottom: 1.5rem;
          }
        `}
      >
        <Header light={light} />
        <Main>{children}</Main>
        {light && <Footer />}
      </article>
    </>
  )
}

export default Layout
