import { graphql, useStaticQuery } from "gatsby"

const useContacts = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        contacts(where: { name: "Контакты на русском" }) {
          nodes {
            contactsCF {
              businessHours
              eMail
              legalAddress
              mailingAddress
              phoneNumber
              workingHours
            }
          }
        }
      }
    }
  `)

  return data.wordPress.contacts.nodes[0].contactsCF
}

export default useContacts
