import React from "react"
import { css } from "@emotion/react"

const Row = ({ children, margin, className }) => {
  return (
    <div
      className={className}
      css={css`
        max-width: 93vw;
        margin: 0 auto;
        width: 1780px;
      `}
    >
      {children}
    </div>
  )
}

export default Row
